.container_for_otp {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px;
}

.logo_container {
  margin-right: 80px;
  width: 350px;
  height: 48px;
  text-align: center;
}

.input_container {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
}

/* .Main_Container {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
} */
.otp_bg_box {
  border: 1px solid gainsboro;
  width: 500px;
  border-color: #c7c7c7;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  height: 540px;
  position: relative;
}

.main_heading_text {
  color: #212529;
  font-size: 40px;
}

.subText {
  font-size: 16px;
  color: #212529;
  margin-bottom: 10px;
}

.submit_button {
  margin-top: 50px;
  color: #0d6efd;
  min-width: 200px;
  border-radius: 150px;
  padding: 15px 30px;
  font-weight: 500;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.45);
  border: 1px solid #0d6efd;
}

.submit_button:hover {
  background: #0d6efd;
  color: white;
}

.verification_font {
  font-size: 28px;
  color: #2c3e50;
  margin-top: 25px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 5px;
  width: 100%;
  background-color: #f26d6d;
  color: #fff;
  line-height: 2.5em;
  font-size: 16px;
  text-align: center;
}

.error_heading {
  font-size: 50px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}

@media (max-width: 767px) {
  .container_for_otp {
    display: block;
    justify-content: center;
    align-items: center;
    padding: 39px;
  }

  .footer {
    width: 100%;
  }

  .otp_bg_box {
    margin-top: 80px;
    width: 350px;
    height: 400px;
  }

  .verification_font {
    margin-top: 10px;
  }
}
